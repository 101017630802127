import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Pages/general"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Página Não Encontrada"
      description="Ops, parece que não encontramos o que você busca, pode ser algum erro de sistema ou de digitação, não se preocupe, acesse através dos links na página o lugar desejado."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle>Que pena :( - Página não encontrada</S.GeneralTitle>
      <S.GeneralText>
        A página que você tentou buscar não foi encontrada. Mas você pode usar
        algumas dessas abaixo:
      </S.GeneralText>
      <S.GeneralFinalCall href="https://aprendendodesenhar.com">
        Home
      </S.GeneralFinalCall>
      <S.GeneralFinalCall href="https://bit.ly/aprendendo-desenhar-fan-art">
        Adquirir o curso Agora
      </S.GeneralFinalCall>
      <S.GeneralFinalCall href="https://go.hotmart.com/E32329732K">
        Curso de pintura de FanArt
      </S.GeneralFinalCall>
      <S.GeneralFinalCall href="https://go.hotmart.com/G40189893I">
        Aproveitar o combo ultimate - x
      </S.GeneralFinalCall>
    </S.GeneralWrapper>
  </Layout>
)

export default NotFoundPage
